@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-background text-textPrimary;
}

a {
  @apply text-primary no-underline;
}

a:hover {
  @apply text-primary-dark;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-container {
  @apply bg-white p-6 rounded-lg shadow-md;
}

.form-section {
  @apply mb-6;
}

.form-section h2 {
  @apply text-xl font-semibold mb-4;
}

.form-group {
  @apply mb-4;
}

.form-group label {
  @apply block text-textPrimary font-medium mb-2;
}

.form-group input {
  @apply w-full p-2 border border-gray-300 rounded;
}

.form-group input[type="checkbox"] {
  @apply inline-block w-auto;
}

.form-actions {
  @apply mt-6 flex space-x-4;
}

.form-actions button {
  @apply bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark;
}
@import "leaflet/dist/leaflet.css";
@import "leaflet/dist/leaflet.css";
/* React DatePicker Custom Styles */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: inherit;
  border-color: #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.react-datepicker__header {
  background-color: #f3f4f6;
  border-bottom-color: #e5e7eb;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  font-weight: 600;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected {
  background-color: #3b82f6 !important;
}

.react-datepicker__day:hover,
.react-datepicker__time-list-item:hover {
  background-color: #93c5fd !important;
}

.react-datepicker__time-container {
  border-left-color: #e5e7eb;
}

.react-datepicker-popper {
  z-index: 20;
}
/* Rich Text Editor Styles */
.ql-editor {
    min-height: 500px;
    font-size: 16px;
    line-height: 1.5;
}

.ql-toolbar {
    border-bottom: 1px solid #e5e7eb !important;
    background-color: #f9fafb;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.ql-container {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.prose {
    max-width: none;
}

.prose h1 {
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    font-weight: bold;
}

.prose h2 {
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    font-weight: bold;
}

.prose h3 {
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
}

.prose p {
    margin-top: 1em;
    margin-bottom: 1em;
}

.prose ul {
    list-style-type: disc;
    padding-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.prose ol {
    list-style-type: decimal;
    padding-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.prose li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.prose a {
    color: #2563eb;
    text-decoration: underline;
}

.prose strong {
    font-weight: 600;
}

.prose em {
    font-style: italic;
}
